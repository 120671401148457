/* eslint-disable global-require */
<template>
  <div id="user-profile">
    <user-header />
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          >
            #{{ data.item.transaction_number }}
          </b-link>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.user.name)"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.user.name }}
            </span>
            <small class="text-muted">{{ data.item.user.email }}</small>
          </b-media>
        </template>

        <template #cell(paymentType)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.type }}
            </span>
          </b-media>
        </template>

        <template #cell(type)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.type }}
            </span>
          </b-media>
        </template>

        <template #cell(total)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.total_amount }} $
            </span>
          </b-media>
        </template>

        <template #cell(net)="data">
          <span
            v-if="data.item"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.net_amount }} $
          </span>
        </template>

        <template #cell(monthly)="data">
          <b-avatar
            :id="`invoice-row-${data.item.id}`"
            size="32"
            :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.monthly).variant}`"
          >
            <feather-icon
              :icon="resolveInvoiceStatusVariantAndIcon(data.item.monthly).icon"
            />
          </b-avatar>
        </template>
      </b-table>
      <div
        v-if="loading"
        class="loader"
      />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalInvoices"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import UserHeader from './UserHeader.vue'

export default {
  components: {
    UserHeader,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BTooltip,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  data() {
    return {
      fetchInvoices: [],
      totalInvoices: null,
      loading: false,
    }
  },
  setup() {
    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]
    const tableColumns = [
      { key: 'id', label: 'Transaction ID', sortable: true },
      { key: 'client', label: 'Donor Name', sortable: true },
      { key: 'paymentType', label: 'Payment Type', sortable: true },
      { key: 'type', label: 'Type', sortable: true },
      { key: 'total', sortable: true, formatter: val => `$${val}` },
      { key: 'net', sortable: true, formatter: val => `$${val}` },
      { key: 'monthly', sortable: true },
      // { key: 'actions' },
    ]
    const perPage = ref(5)
    const perPageOptions = [10, 25, 50, 100]
    const resolveInvoiceStatusVariantAndIcon = status => {
      if (status === false) return { variant: 'warning', icon: 'InfoIcon' }
      if (status === true) return { variant: 'success', icon: 'CheckCircleIcon' }
      return {}
    }
    const searchQuery = ''
    const currentPage = ref(1)
    // eslint-disable-next-line camelcase
    const formData = {
      entity_id: 1,
    }
    return {
      tableColumns,
      statusOptions,
      avatarText,
      perPage,
      perPageOptions,
      resolveInvoiceStatusVariantAndIcon,
      searchQuery,
      currentPage,
      formData,
    }
  },
  created() {
    this.getTransactions()
  },
  methods: {
    getTransactions() {
      this.loading = true
      axios.post('https://entities-microsevice.muslimdo.com/api/internalops/getEntityPaymentTransactions', this.formData).then(res => {
        this.fetchInvoices = res.data.data.data
        this.totalInvoices = this.fetchInvoices.length
        console.log(this.fetchInvoices.length)
        console.log(res.data.data.data)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
